import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

export default function Blog_1_4() {
  return (
    <div className="blog-container">

      <h2 className='blog-title'>
        Physical Models
      </h2>

      <p>
        by <HashLink className='blog-author' to='/blogs/about_me'>Daniel Cho</HashLink> | Nov. 25, 2023
      </p>

      <p>
        This page contains a collection of physical models made in college and high school.
      </p>

      <h3 className='blog-subtitle'>1. Church Project</h3>

      <img className='blog-img' src='./../blog_1_4/0-0.jpg'/>
      <p className='blog-img__caption'>Church project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/0-1.jpg'/>
      <p className='blog-img__caption'>Church project image 2.</p>

      <img className='blog-img' src='./../blog_1_4/0-2.jpg'/>
      <p className='blog-img__caption'>Church project image 3.</p>

      <img className='blog-img' src='./../blog_1_4/0-3.jpg'/>
      <p className='blog-img__caption'>Church project image 4.</p>

      <img className='blog-img' src='./../blog_1_4/0-4.jpg'/>
      <p className='blog-img__caption'>Church project image 5.</p>


      <h3 className='blog-subtitle'>2. House Project</h3>

      <img className='blog-img' src='./../blog_1_4/1-0.jpg'/>
      <p className='blog-img__caption'>House project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/1-1.jpg'/>
      <p className='blog-img__caption'>House project image 2.</p>

      <img className='blog-img' src='./../blog_1_4/1-2.jpg'/>
      <p className='blog-img__caption'>House project image 3.</p>

      <img className='blog-img' src='./../blog_1_4/1-3.jpg'/>
      <p className='blog-img__caption'>House project image 4.</p>

      <img className='blog-img' src='./../blog_1_4/1-4.jpg'/>
      <p className='blog-img__caption'>House project image 5.</p>


      <h3 className='blog-subtitle'>2. Visualization Project</h3>

      <img className='blog-img' src='./../blog_1_4/2-0.jpg'/>
      <p className='blog-img__caption'>Visualization project image 1.</p>


      <h3 className='blog-subtitle'>3. Construction Detail Project</h3>

      <img className='blog-img' src='./../blog_1_4/3-0.jpg'/>
      <p className='blog-img__caption'>Construction detail project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/3-1.jpg'/>
      <p className='blog-img__caption'>Construction detail project image 2.</p>

      <img className='blog-img' src='./../blog_1_4/3-2.jpg'/>
      <p className='blog-img__caption'>Construction detail project image 3.</p>


      <h3 className='blog-subtitle'>4. Strange House Project</h3>

      <img className='blog-img' src='./../blog_1_4/4-0.JPG'/>
      <p className='blog-img__caption'>Strange house project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/4-1.JPG'/>
      <p className='blog-img__caption'>Strange house project image 2.</p>

      <img className='blog-img' src='./../blog_1_4/4-2.JPG'/>
      <p className='blog-img__caption'>Strange house project image 3.</p>


      <h3 className='blog-subtitle'>5. Modern House Project</h3>

      <img className='blog-img' src='./../blog_1_4/5-0.JPG'/>
      <p className='blog-img__caption'>Modern house project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/5-1.JPG'/>
      <p className='blog-img__caption'>Modern house project image 2.</p>

      <img className='blog-img' src='./../blog_1_4/5-2.JPG'/>
      <p className='blog-img__caption'>Modern house project image 3.</p>


      <h3 className='blog-subtitle'>6. Floating House Project</h3>

      <img className='blog-img' src='./../blog_1_4/6-0.JPG'/>
      <p className='blog-img__caption'>Floating house project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/6-1.JPG'/>
      <p className='blog-img__caption'>Floating house project image 2.</p>

      <img className='blog-img' src='./../blog_1_4/6-2.JPG'/>
      <p className='blog-img__caption'>Floating house project image 3.</p>


      <h3 className='blog-subtitle'>7. Floating House Project</h3>

      <img className='blog-img' src='./../blog_1_4/7-0.JPG'/>
      <p className='blog-img__caption'>Repurposed house project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/7-1.JPG'/>
      <p className='blog-img__caption'>Repurposed house project image 2.</p>

      
      <h3 className='blog-subtitle'>8. Slum House Project</h3>

      <img className='blog-img' src='./../blog_1_4/8-0.JPG'/>
      <p className='blog-img__caption'>Slum house project image 1.</p>

      <img className='blog-img' src='./../blog_1_4/8-1.JPG'/>
      <p className='blog-img__caption'>Slum house project image 2.</p>

      <img className='blog-img' src='./../blog_1_4/8-2.JPG'/>
      <p className='blog-img__caption'>Slum house project image 3.</p>


      <h3 className='blog-subtitle'>8. Lighting Project</h3>

      <img className='blog-img' src='./../blog_1_4/9-0.png'/>
      <p className='blog-img__caption'>Lighting project image 1.</p>

    </div>
  )
}